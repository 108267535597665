var time;
var showAssistance = function () {
    var d = document.getElementById("are_you_there");
    d.style.display = "block";
}

var resetTimer = function (evnt) {
    var showHelpInSeconds = 9;
    clearTimeout(time);
    time = setTimeout(showAssistance, showHelpInSeconds * 1000)
}

var hideHelp = function () {
    removeActivityHandlers();

}

var attachActivityHandlers = function () {
    window.onload = resetTimer;
    document.addEventListener('mousemove', resetTimer, false);
    document.addEventListener('mousedown' , resetTimer, false); // touchscreen presse
    document.addEventListener('touchstart' , resetTimer, false);
    document.addEventListener('click' , resetTimer, false);     // touchpad click
    document.addEventListener('scroll' , resetTimer, false);    // scrolling with arrow key
    document.addEventListener('keypress' , resetTimer, false);
}

var removeActivityHandlers = function () {
    if (document.removeEventListener) {                   // For all major browsers, except IE 8 and earlier
            clearTimeout(time);
            document.removeEventListener('mousemove',resetTimer, false);
            document.removeEventListener('mousedown',resetTimer, false);
            document.removeEventListener('touchstart',resetTimer, false);
            document.removeEventListener('click',resetTimer, false);
            document.removeEventListener('scroll',resetTimer, false);
            document.removeEventListener('keypress',resetTimer, false);
        } else if (document.detachEvent) {                    // For IE 8 and earlier versions

            document.detachEvent('mousemove',resetTimer, false);
            document.detachEvent('mousedown',resetTimer, false);
            document.detachEvent('touchstart',resetTimer, false);
            document.detachEvent('click',resetTimer, false);
            document.detachEvent('scroll',resetTimer, false);
            document.detachEvent('keypress',resetTimer, false);
    }

    var d = document.getElementById("are_you_there");
    d.style.display = "none";
}

attachActivityHandlers();
